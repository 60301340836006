import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TipoPaginaService {
  private tipoPagina: 'pagina' | 'producto';

  constructor() { }

  getTipoPagina(): 'pagina' | 'producto' {
    return this.tipoPagina;
  }

  setTipoPagina(value: 'pagina' | 'producto'): void {
    this.tipoPagina = value;
  }
}
