import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeneralHttpService } from '../general-http/general-http.service';
import { TipoPaginaService } from '../tipo-pagina/tipo-pagina.service';

@Injectable({
  providedIn: 'root'
})
export class ComponentService {

  public dataPage: any = {};

  constructor(private http: GeneralHttpService,
    private tipoPagina: TipoPaginaService){
  }

  getContentComponetByPage<T>(component: string, page: string): Observable<any>{
      let data: any;

      if (this.dataPage[page]){
          if (this.dataPage[page][component]){
              data = this.dataPage[page][component];
          }
      }

      if (data){
          return new Observable((observer) => {
              observer.next(data);
          });
      }else{
          const tipo = this.tipoPagina.getTipoPagina()
          
          return this.http.get<T>(`api/v1/web/${tipo}/${page}/componente/${component}/contenido`)
              .pipe(map( m => {
                  if (this.dataPage[page] === undefined){ this.dataPage[page] = []; }
                  if (this.dataPage[page][component]){ this.dataPage[page][component] = []; }
                  return this.dataPage[page][component] = m;
              }));
      }
  }
}
